<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  setup (props, { expose }) {
    const visible = ref(true)

    expose({ visible })

    return {
      visible
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00F;
}
</style>
